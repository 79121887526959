import React, { ReactNode } from "react";
import styles from "./pageHeader.module.scss";

export interface IPageHeaderProps {
  title: ReactNode | string;
  children: ReactNode | string;
}

export function PageHeader(props: IPageHeaderProps) {
  const { title, children } = props;

  return (
    <div className={styles.Header}>
      <div className={styles.Wrapper}>
        <h1 className={styles.Title}>{title}</h1>

        <p className={styles.Text}>{children}</p>
      </div>

      <img
        className={styles.RocketImage}
        width="1920"
        height="351"
        src={"/images/frame-cloud2.svg"}
        alt={""}
      />
    </div>
  );
}
