import { useSiteNameContext } from "../../../context/siteNameContext";
import { Analytics } from "../../../widgets/analytics";
import { EntryWorks } from "../../../widgets/entryWorks";
import { Olympiades } from "../../../widgets/olympiades";
import { PageHeader } from "../../../widgets/pageHeader";
import { Research } from "../../../widgets/research";
import { Teacher } from "../../../widgets/teacher";
import styles from "./mainPage.module.scss";

export function MainPage() {
  const { siteNameList } = useSiteNameContext();

  document.title =
    siteNameList.length && siteNameList.find((x: any) => x.name === "главная")
      ? siteNameList.find((x: any) => x.name === "главная").title
      : "Главная";

  return (
    <>
      <PageHeader
        title={
          <>
            ОНЛАЙН ТРЕНАЖЕР И РЕПЕТИТОРЫ <br /> ДЛЯ УЧЕНИКОВ 2-4 КЛАССА, <br />
            ПОСТУПАЮЩИХ В ФМЛ
          </>
        }
      >
        <>
          Подготовка к поступлению в математические классы <br /> школ Москвы и
          Санкт-Петербурга
        </>
      </PageHeader>

      <div className={styles.Wrapper}>
        <EntryWorks />
        <Olympiades />
        <Teacher />
        <Research />
        <Analytics />
      </div>
    </>
  );
}
