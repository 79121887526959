import { useEffect, useState } from "react";
import { useAuthContext } from "../../../context/authContext";
import Logo from "../../../shared/ui/logo/logo";
import { Toggler } from "../../../shared/ui/toggler";
import { NavBarNavigation } from "./navBarNavigation";
import styles from "./navBar.module.scss";
import { links } from "../types/links";

export function NavBar() {
  const { isAuth } = useAuthContext();
  const [isDropDownToggled, toggleDropDown] = useState(false);
  const [isToggled, setIsToggled] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  // @ts-ignore
  const authInfo = JSON.parse<TUserAuth>(localStorage.getItem("auth"));
  useEffect(() => {}, [isAuth]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY) {
        setIsToggled(false);
      } else {
        setIsToggled(true);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  function mobileNavigation() {
    if (isDropDownToggled === true) {
      return (
        <NavBarNavigation
          links={links}
          authInfo={authInfo}
          onClick={() => toggleDropDown(!isDropDownToggled)}
        />
      );
    }
  }

  if (isToggled) {
    return (
      <div className={styles.NavBar}>
        <Logo className={styles.Logo} />

        <div className={styles.MobileDropDown}>
          <Logo />
          <div className={styles.MobileDropDown}>{mobileNavigation()}</div>
        </div>

        <div className={styles.MobileNavigation}>
          <Toggler onClick={() => toggleDropDown(!isDropDownToggled)} />
        </div>

        <div className={styles.DesktopNavigation}>
          <NavBarNavigation
            links={links}
            authInfo={authInfo}
            onClick={() => toggleDropDown(!isDropDownToggled)}
          />
        </div>
      </div>
    );
  }

  return <></>;
}
